import React, { useState } from "react";
import Select from 'react-select';
import { navigate } from "gatsby-link";
import { theme } from "../../../globals/theme";
import Content from "../../Content";
import { Section, TopContainer, Button } from "../../styles";
import { FieldGroup, Label, Input, FormContainer, Form, Field, TextArea, Title } from "./styles";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const ContactPageComponent = ({
  title,
  body,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  // const [isValidated, setIsValidated] = useState(false);
  const [values, setValues] = useState({});

  const knownOptions = [
    {
      value: 'Recherche Google',
      label: 'Recherche Google',
    },
    {
      value: 'Podcast',
      label: 'Podcast',
    },
    {
      value: 'Réseaux sociaux',
      label: 'Réseaux sociaux',
    },
    {
      value: 'Référence',
      label: 'Référence',
    },
  ];

  const serviceTypeOptions = [
    {
      value: 'Coaching individuel',
      label: 'Coaching individuel',
    },
    {
      value: 'Coaching d’équipe',
      label: 'Coaching d’équipe',
    },
    {
      value: 'Coaching individuel et d’équipe',
      label: 'Coaching individuel et d’équipe',
    }
  ];

  const knownStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 0',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
      height: '2rem',
    }),
    control: (provided) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
      fontFamily: theme.font.family,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "32px",
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      color: 'white',
      background: theme.colors.blue,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      background: theme.colors.blue,
      fontFamily: theme.font.family,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "32px",
    }),
    option: (provided) => ({
      ...provided,
      color: 'white',
      background: theme.colors.blue,
      fontFamily: theme.font.family,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "32px",
    }),
  };

  const handleChange = (e) => {
    if(!e || !e.target){
      setValues({
        ...values,
        known: e?.value
      });
    }else{
      setValues({
        ...values,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...values,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Section variant="contact">
      <TopContainer>
        <Title variant="others">
          {title}
        </Title>
        <PageContent content={body} />
      </TopContainer>
      <FormContainer>
        <Form
          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>
          <FieldGroup>
            <Field>
              <Label htmlFor={"firsname"}>
                Pr&eacute;nom
              </Label>
              
                <Input
                  className="input"
                  type={"text"}
                  name={"firsname"}
                  placeholder="Prénom *"
                  onChange={handleChange}
                  id={"firsname"}
                  required={true}
                />
            </Field>
            <Field>
              <Label htmlFor={"name"}>
                Nom
              </Label>
              
                <Input
                  className="input"
                  type={"text"}
                  name={"name"}
                  placeholder="Nom *"
                  onChange={handleChange}
                  id={"name"}
                  required={true}
                />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <Label htmlFor={"email"}>
                Email
              </Label>
              
                <Input
                  className="input"
                  type={"email"}
                  name={"email"}
                  placeholder="Email *"
                  onChange={handleChange}
                  id={"email"}
                  required={true}
                />
            </Field>
            <Field>
              <Label htmlFor={"phone"}>
                Téléphone
              </Label>
              
                <Input
                  className="input"
                  type={"phone"}
                  name={"phone"}
                  placeholder="Téléphone *"
                  onChange={handleChange}
                  id={"phone"}
                  required={true}
                />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <Label htmlFor={"company"}>
                Entreprise
              </Label>
              
                <Input
                  className="input"
                  type={"text"}
                  name={"company"}
                  placeholder="Entreprise"
                  onChange={handleChange}
                  id={"company"}
                />
            </Field>
            <Field>
              <Label htmlFor={"employees"}>
                Nombre d'employé(e)s
              </Label>
              
                <Input
                  className="input"
                  type={"number"}
                  name={"employees"}
                  placeholder="Nombre d'employé(e)s"
                  onChange={handleChange}
                  id={"employees"}
                />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <Label htmlFor={"serviceType"}>Type de service *</Label>
              <Select 
                className="select"
                options={serviceTypeOptions}
                id="serviceType"
                name="serviceType"
                placeholder="Type de service *"
                onChange={handleChange}
                styles={knownStyles}
                isClearable
              />
            </Field>
            <Field>
              <Label htmlFor={"known"}>Comment nous avez-vous connu ? *</Label>
              <Select 
                className="select"
                options={knownOptions}
                id="known"
                name="known"
                placeholder="Comment nous avez-vous connu?*"
                onChange={handleChange}
                styles={knownStyles}
                isClearable
              />
            </Field>
          </FieldGroup>
            <Label htmlFor={"message"}>
              Message
            </Label>
              <TextArea
                className="textarea"
                name={"message"}
                onChange={handleChange}
                id={"message"}
                placeholder="Message *"
                required={true}
              />
            <Button className="submit-button" type="submit">
              Envoyer
            </Button>
        </Form>
      </FormContainer>
    </Section>
  );
}

export default ContactPageComponent;
