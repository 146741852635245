import styled from "styled-components";

export const Title = styled.h2`
  font-weight: 600;
  font-size: 5.625rem;
  line-height: 6.25rem;
`;


export const FormContainer = styled.div`
  margin: 57px auto 0;
  @media (min-width: 1024px) {
    max-width: 58%;
    margin: 88px auto 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-content: center;
  & button.submit-button {
    margin: 67px auto;
    width: 16em;
    background: transparent;
    font-family: ${props => props.theme.font.family};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const Field = styled.div`
  flex-grow: 1;
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    width: 49%;
  }
  &>div.select {
    margin-top: -8px;
  }
`;


export const Label = styled.label`
  display: none;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  outline: 0;
  width: 100%;
  height: 2rem;
  &::placeholder{
    color: white;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number]{
    -moz-appearance: textfield;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 10.313rem;
  vertical-align: top;
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  margin: 0 5px;
  font-family: ${props => props.theme.font.family};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: white;
  &:focus {
    outline: 0;
    resize: none;
  }
`;