import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { ContactPageComponent } from "../components/Pages/Contact";

// eslint-disable-next-line
export const ContactPageTemplate = (props) => {
  return (
    <ContactPageComponent {...props} />
  );
};

ContactPageTemplate.propTypes = {
  // TODO
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContactPage = ({ data }) => {
  const { markdownRemark: content } = data;
  const { frontmatter } = content;
  return (
    <Layout>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        body={content.html}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export { Head } from "../components/Core/HtmlHead";

export const contactPageQuery = graphql`
  query contactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter{
        title
      }
    }
  }
`;
